import {
  AllIndexedDbStores,
  indexedDbStoresName,
  indexedDbSystemStoresName,
} from '@models/indexed-db-stores.model';
import { OfflineId } from '@models/offline.model';
import { from, map, Observable } from 'rxjs';
import { DB_NAME, DB_VERSION } from './core-indexed-db.constants';

interface Identifiable {
  id: OfflineId;
}

export abstract class CoreIndexedDbService {
  protected abstract storeName: AllIndexedDbStores;
  protected dbName = DB_NAME;
  protected dbVersion = DB_VERSION;
  protected storeParameters: IDBObjectStoreParameters = {
    keyPath: null,
  };

  readonly storesName: indexedDbStoresName[] = [
    'tasks',
    'markers',
    'notes',
    'samplings',
    'sampling-markers',
  ];
  readonly systemStoresName: indexedDbSystemStoresName[] = ['offline'];

  constructor() {
    this.initDB();
  }

  deleteAllData(): Observable<void> {
    return from(this.getDBConnection()).pipe(
      map((db) => {
        this.storesName.forEach((storeName) =>
          this.deleteAllRecords(db, storeName),
        );
        return;
      }),
    );
  }

  protected getDBConnection(): Promise<IDBDatabase> {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(this.dbName, this.dbVersion);

      request.onsuccess = (): void => {
        resolve(request.result);
      };

      request.onerror = (): void => {
        reject(request.error);
      };
    });
  }

  // CRUD abstract methods
  protected addRecord<T extends Identifiable>(
    db: IDBDatabase,
    storeName: string,
    record: T,
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(storeName, 'readwrite');
      const store = transaction.objectStore(storeName);
      const key = record.id;
      const request = store.put(record, key);

      request.onsuccess = (): void => {
        resolve(request.result as number);
      };

      request.onerror = (): void => {
        reject(request.error);
      };
    });
  }

  protected getRecord<T>(
    db: IDBDatabase,
    storeName: string,
    indexedDbId: OfflineId,
  ): Promise<T | undefined> {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(storeName, 'readonly');
      const store = transaction.objectStore(storeName);
      const request = store.get(indexedDbId);

      request.onsuccess = (): void => {
        resolve(request.result as T);
      };

      request.onerror = (): void => {
        reject(request.error);
      };
    });
  }

  protected async updateRecord<T>(
    db: IDBDatabase,
    storeName: string,
    indexedDbId: OfflineId,
    updatedRecord: Partial<T>,
  ): Promise<void> {
    const transaction = db.transaction(storeName, 'readwrite');
    const store = transaction.objectStore(storeName);

    const originalRecord = await new Promise<T>((resolve, reject) => {
      const getRequest = store.get(indexedDbId);

      getRequest.onsuccess = (): void => {
        resolve(getRequest.result as T);
      };

      getRequest.onerror = (): void => {
        reject(getRequest.error);
      };
    });

    const mergedRecord = { ...originalRecord, ...updatedRecord };

    return new Promise((resolve, reject) => {
      const putRequest = store.put({ ...mergedRecord, indexedDbId });

      putRequest.onsuccess = (): void => {
        resolve();
      };

      putRequest.onerror = (): void => {
        reject(putRequest.error);
      };
    });
  }

  protected deleteRecord(
    db: IDBDatabase,
    storeName: string,
    indexedDbId: OfflineId,
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(storeName, 'readwrite');
      const store = transaction.objectStore(storeName);
      const request = store.delete(indexedDbId);

      request.onsuccess = (): void => {
        resolve();
      };

      request.onerror = (): void => {
        reject(request.error);
      };
    });
  }

  protected getAllRecords<T>(db: IDBDatabase, storeName: string): Promise<T[]> {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(storeName, 'readonly');
      const store = transaction.objectStore(storeName);
      const request = store.getAll();

      request.onsuccess = (): void => {
        resolve(request.result as T[]);
      };

      request.onerror = (): void => {
        reject(request.error);
      };
    });
  }

  protected deleteAllRecords(
    db: IDBDatabase,
    storeName: string,
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(storeName, 'readwrite');
      const store = transaction.objectStore(storeName);
      const request = store.clear();

      request.onsuccess = (): void => {
        resolve();
      };

      request.onerror = (): void => {
        reject(request.error);
      };
    });
  }

  private initDB(): void {
    const request = indexedDB.open(this.dbName, this.dbVersion);

    request.onupgradeneeded = (): void => {
      const db = request.result;

      [...this.storesName, ...this.systemStoresName].forEach((storeName) => {
        if (!db.objectStoreNames.contains(storeName)) {
          db.createObjectStore(storeName, this.storeParameters);
        }
      });
    };

    request.onerror = (): void => {
      console.error('IndexedDB error:', request.error);
    };
  }
}
